import "react-multi-carousel/lib/styles.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const PublicLayout = ({ children }) => {
  return (
    <section className="flex flex-col min-h-screen relative">
      <Navbar />
      <section className="basis-full flex-shrink-0 overflow-hidden">
        {children}
      </section>
      <Footer />
    </section>
  );
};

export default PublicLayout;
