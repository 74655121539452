import DashboardSidebar from "./components/Sidebar";
import DashboardHeader from "./components/Header";
import { Stack } from "@chakra-ui/react";

const DashboardLayout = ({ children }) => {
  return (
    <Stack  gap="0">
      <DashboardHeader />
      <section className="relative flex h-screen">
        <DashboardSidebar />
        <section className="content flex w-full h-full">
          <section className="hidden xlg:block w-72 h-full flex-shrink-0"></section>
          <section className="h-screen flex-grow bg-border relative overflow-hidden">
            <section className="overflow-x-hidden dashboard--content relative bg-grey500">
              {children}
            </section>
          </section>
        </section>
      </section>
    </Stack>
  );
};

export default DashboardLayout;
