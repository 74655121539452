/** @format */

import { Path } from "utils/constant.data";
import ContactPage from "pages/Landing/contact";
import LoginPage from "pages/auth/Login";
import RegisterPage from "pages/auth/Register";
import DashboardOverview from "pages/dashbaord";
import FaqPage from "pages/Landing/faq";
import TermsPage from "pages/Landing/terms";
import PrivacyPage from "pages/Landing/privacy"

const PublicRoutes = [
  {
    path: Path.LOGIN,
    element: <LoginPage />,
    title: Path.LOGIN,
  },
  {
    path: Path.REGISTER,
    element: <RegisterPage />,
    title: Path.REGISTER,
  },
  {
    path: Path.CONTACT,
    element: <ContactPage />,
    title: Path.CONTACT,
  },
  {
    path: Path.FAQS,
    element: <FaqPage />,
    title: Path.FAQS,
  },
  {
    path: Path.TERMS,
    element: <TermsPage />,
    title: Path.TERMS,
  },
  {
    path: Path.PRIVACY_POLICY,
    element: <PrivacyPage />,
    title: Path.PRIVACY_POLICY,
  },
];

const AuthUserRoutes = [
  {
    path: Path.DASHBOARD_OVERVIEW,
    element: <DashboardOverview />,
    title: Path.DASHBOARD_OVERVIEW,
  }
];

export { PublicRoutes, AuthUserRoutes };
