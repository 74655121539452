/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQuery";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body) => ({
        url: "merchant/login",
        method: "POST",
        body,
      }),
    }),
    registerUserEmail: builder.mutation({
      query: (body) => ({
        url: "merchant/register",
        method: "POST",
        body,
      }),
    }),
    finalizeUserEmail: builder.mutation({
      query: (body) => ({
        url: "merchant/register/finalize",
        method: "POST",
        body,
      }),
    }),
    saveUserPackage: builder.mutation({
      query: (body) => ({
        url: "merchant/register/setpackage/",
        method: "POST",
        body,
      }),
    }),
    getProfile: builder.query({
      query: () => `accounts/profile`,
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserEmailMutation,
  useFinalizeUserEmailMutation,
  useSaveUserPackageMutation,
  useGetProfileQuery,
} = authApi;
