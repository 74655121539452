/* eslint-disable no-unused-vars */
import { ReactComponent as WeaveLogo } from "icons/logo.svg";
import { Path } from "utils/constant.data";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Navbar = ({ path }) => {
  const navigate = useNavigate();

  return (
    <header className="charend--header">
      <figure onClick={() => navigate(Path.HOME)}>
        <WeaveLogo />
      </figure>
      <ul className="hidden md:flex items-center">
        <li>
          <Link
            onClick={(e) => {
              e.preventDefault();
              const target = e.target;
              const id = target.getAttribute("href")?.replace("#", "");
              const element = document.getElementById("about");
              element?.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            About Us
          </Link>
        </li>
        <li>
          <Link
            onClick={(e) => {
              e.preventDefault();
              const target = e.target;
              const id = target.getAttribute("href")?.replace("#", "");
              const element = document.getElementById("features");
              element?.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            Features
          </Link>
        </li>
        <li>
          <Link to={Path.CONTACT}>Contact Us</Link>
        </li>
        <li>
          <Link to={Path.FAQS}>FAQs</Link>
        </li>
        <li>
          <button className="btn btn-primary"> Download</button>
        </li>
      </ul>
    </header>
  );
};

export default Navbar;
