/* eslint-disable no-unused-vars */
import React from "react";
import { Stack, Center, Text, HStack } from "@chakra-ui/react";
import PublicLayout from "layouts/public";

const TermsPage = () => {
  return (
    <PublicLayout>
      <Stack gap="0" className="bg-[#FAF8F1] !flex-row justify-center ">
        <Stack className="w-[85%] md:w-1/2 mt-56">
          <section className="charend--board">
            <section className="!bg-red-500 charend__bg"> </section>
            <h2 className="font-medium text-[32px]">Charend Terms and Conditions</h2>
          </section>

          <HStack className="!justify-end !w-full !mb-10">
            <Text className="!font-montserrat text-sm font-bold">
              Last Updated: 08/29/24
            </Text>
          </HStack>

          <Stack className="charend--richtext">
            <Text pb={4} className="!text-lg !font-montserrat !font-normal">
              Welcome to charend By downloading, accessing, or using our app,
              you agree to comply with and be bound by the following terms and
              conditions. Please read them carefully.
            </Text>
            <Text>
              1. Acceptance of TermsBy creating an account or using charend you
              accept these Terms and Conditions and our Privacy Policy. If you
              do not agree, please do not use our app.
            </Text>

            <Text>
              2. EligibilityYou must be at least 18 years old to use charend. By
              using the app, you represent and warrant that you meet this age
              requirement.
            </Text>

            <Text>
              3. Account SecurityYou are responsible for maintaining the
              confidentiality of your account credentials and for all activities
              that occur under your account. Please notify us immediately if you
              suspect any unauthorized use of your account.
            </Text>

            <Text>
              4. User ConductYou agree to use charend only for lawful purposes
              and in a way that does not infringe the rights of, restrict, or
              inhibit anyone else's use of the app. Prohibited conduct includes,
              but is not limited to, harassment, abusive behavior, and sharing
              of harmful or illegal content.
            </Text>

            <Text>
              5. Background Checkscharend performs criminal checks and identity
              verification through our parent company, Prembly Inc. All users
              are required to present a valid ID as part of this process. While
              we strive for accuracy, we cannot guarantee the completeness or
              reliability of these checks. We encourage you to use your own
              judgment and discretion when interacting with other users.
            </Text>

            <Text>
              6. Privacy and Data UseYour privacy is important to us. Our
              Privacy Policy, which is incorporated into these Terms and
              Conditions, explains how we collect, use, and protect your
              information. We ensure that your data will not be used for any
              purpose outside the core functions of the app without your
              explicit consent.
            </Text>

            <Text>
              7. Subscriptions and Paymentscharend may is subscription based. By
              subscribing or making a payment, you agree to the applicable
              pricing and payment terms. All fees are non-refundable unless
              otherwise stated.
            </Text>

            <Text>
              8. TerminationWe reserve the right to suspend or terminate your
              account at any time, with or without notice, for any reason,
              including but not limited to, breach of these Terms and
              Conditions.
            </Text>

            <Text>
              9. Intellectual PropertyAll content, trademarks, and data on
              charend are owned by us or our licensors. You may not use or
              reproduce any of this content without our prior written consent.
            </Text>

            <Text>
              10. Limitation of Liabilitycharend is provided "as is" and without
              warranties of any kind. We are not liable for any damages arising
              from your use of the app, including, but not limited to, indirect,
              incidental, or consequential damages.
            </Text>

            <Text>
              11. Changes to TermsWe may update these Terms and Conditions from
              time to time. Any changes will be posted on this page, and your
              continued use of the app after such changes constitutes acceptance
              of the new terms.
            </Text>

            <Text>
              12. Governing LawThese Terms and Conditions are governed by and
              construed in accordance with the laws of USA, Delaware. Any
              disputes arising out of or related to these terms will be subject
              to the exclusive jurisdiction of the courts of USA, Delaware.
            </Text>

            <Text>
              13. Contact UsIf you have any questions about these Terms and
              Conditions, please contact us.
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </PublicLayout>
  );
};

export default TermsPage;
