/* eslint-disable no-unused-vars */
import React from "react";
import { Stack, Center, Text, HStack } from "@chakra-ui/react";
import PublicLayout from "layouts/public";

const PrivacyPage = () => {
  return (
    <PublicLayout>
      <Stack gap="0" className="bg-[#FAF8F1] !flex-row justify-center ">
        <Stack className="w-[85%] md:w-1/2 mt-56">
          <section className="charend--board">
            <section className="!bg-red-500 charend__bg"> </section>
            <h2 className="font-medium text-[32px]">Charend Privacy Policy</h2>
          </section>

          <HStack className="!justify-end !w-full !mb-10">
            <Text className="!font-montserrat text-sm font-bold">Last Updated: 08/29/24</Text>
          </HStack>

          <Stack className="charend--richtext">
            <Text pb={4} className="!text-lg !font-montserrat !font-normal">
              At charend, your privacy is of utmost importance to us. This
              Privacy Policy outlines how we collect, use, and protect your
              personal information when you use our app. By using charend, you
              agree to the terms of this Privacy Policy. 
            </Text>
            Here’s the text enclosed in `<Text></Text>` tags for each section:
            ```xml
            <Text>
              1. Information We CollectWe collect various types of information
              to provide you with the best possible experience: Personal
              Information: When you sign up for charend, we may collect personal
              details such as your name, email address, phone number, date of
              birth, and gender. We also require users to present a valid ID for
              identity verification purposes. Usage Data: We collect information
              about how you use the app, such as your interactions with other
              users, preferences, and the time and duration of your sessions.
              Location Data: With your permission, we may collect and process
              information about your location to provide location-based
              services. Device Information: We collect information about the
              device you use to access charend, including the type of device,
              operating system, and device identifiers.
            </Text>
            <Text>
              2. How We Use Your InformationWe use your information to: Provide
              and Improve Our Services: We use your data to operate charend,
              personalize your experience, and improve our app's functionality.
              Verify Identity: We use your personal information and ID to
              perform background checks and verify your identity through our
              parent company, Prembly Inc. Communicate with You: We may use your
              contact information to send you updates, notifications, and other
              information related to your account and our services. Ensure
              Safety: We use your information to protect our users and maintain
              a safe environment on charend by detecting and preventing fraud,
              abuse, and illegal activities. Comply with Legal Obligations: We
              may use your data to comply with legal and regulatory
              requirements.
            </Text>
            <Text>
              3. How We Share Your InformationWe may share your information
              with: Service Providers: We work with third-party service
              providers, such as Prembly Inc., to perform background checks,
              identity verification, and other essential services. Legal
              Authorities: We may share your information with law enforcement or
              other authorities if required by law or to protect our users'
              safety and rights. Business Transfers: If charend is involved in a
              merger, acquisition, or asset sale, your information may be
              transferred as part of that transaction.
            </Text>
            <Text>
              4. Your Rights and ChoicesYou have control over your personal
              information and can: Access and Update Your Information: You can
              access and update your personal information at any time through
              your account settings. Delete Your Account: You can delete your
              charend account, and we will permanently remove your information
              from our servers. Control Communications: You can opt-out of
              receiving certain communications from us through your account
              settings or by following the unsubscribe instructions in our
              emails. Manage Privacy Settings: You can adjust your privacy
              settings to control who can view your profile and interact with
              you.
            </Text>
            <Text>
              5. Data SecurityWe implement industry-standard security measures
              to protect your data from unauthorized access, disclosure,
              alteration, or destruction. However, no method of transmission
              over the internet or electronic storage is completely secure, and
              we cannot guarantee absolute security.
            </Text>
            <Text>
              6. Data RetentionWe retain your personal information for as long
              as necessary to provide our services, comply with legal
              obligations, resolve disputes, and enforce our agreements. Once
              your data is no longer needed, we will securely delete or
              anonymize it.
            </Text>
            <Text>
              7. Children's Privacycharend is not intended for use by
              individuals under the age of 18. We do not knowingly collect
              personal information from children. If we become aware that a user
              under 18 has provided us with personal information, we will take
              steps to delete such information.
            </Text>
            ```
          </Stack>
        </Stack>
      </Stack>
    </PublicLayout>
  );
};

export default PrivacyPage;
