import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from "react-redux";
import { store } from 'store/store';
import "react-datepicker/dist/react-datepicker.css";


// {
//   sm: "30em",
//   md: "48em" 768,
//   lg: "62em",
//   xl: "80em",
//   "2xl": "96em",
// }



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);

