import { Path } from "utils/constant.data";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-[#FAF8F1] px-50 md:px-100 py-60">
      <ul className="flex items-center">
        <li className="font-medium font-montserrat text-sm md:text-p text-black mr-40">
          <Link to={Path.LOGIN}>Signin</Link>
        </li>
       <li className="font-medium font-montserrat  text-sm md:text-p text-black mr-40">
          <Link to={Path.LOGIN}>FAQ’s</Link>
        </li>
       <li className="font-medium font-montserrat  text-sm md:text-p text-black mr-40">
          <Link to={Path.LOGIN}>Terms & Condition</Link>
        </li>
       <li className="font-medium font-montserrat  text-sm md:text-p text-black ">
          <Link to={Path.LOGIN}>Privacy Policy</Link>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
