/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "utils/strings";
import baseQueryWithReauth from "./baseQuery";

export const businessApi = createApi({
  reducerPath: "businessApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
  ],
  endpoints: (builder) => ({
   retrievSession: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/peform/credit`,
          method: "POST",
          body,
        };
      },
      providesTags: [API_TAGS.PERFORM_CREDIT_CHECK],
      // invalidatesTags: [API_TAGS.GET_DOCUMENTS],
    }),
    getSessionDetails: builder.query({
      query: (id) => `sdk/session/${id}`,
    }),
    getConsentWithBvn: builder.query({
      query: (id) => `sdk/sdk-verification/${id}/generate_consent/`,
    }),
    getAllAccounts: builder.query({
      query: (id) => `sdk/sdk-verification/${id}/get_accounts/`,
    }),
  }),
});

export const {
  useCreditCheckMutation,
  useGetSessionDetailsQuery,
  useGetConsentWithBvnQuery,
  useGetAllAccountsQuery
} = businessApi;
