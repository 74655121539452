/** @format */
import * as Yup from "yup";

export const replaceUnderscoresWithSpaces = (inputString) => {
  return inputString ? inputString.replace(/_/g, " ") : "";
};

// export const capitalize = (value) => {
//   if (typeof value !== "string") return "";
//   const words = value?.toLocaleLowerCase().trim().split(" ");
//   return words
//     .map((word) =>
// word?.length === 0 ? word : word[0].toUpperCase() + word.substring(1)
//     )
//     .join(" ");
// };

export const capitalize = (sentence) => {
  // if (typeof value !== "string") return "";

  if (!sentence || sentence.length === 0) {
    return "";
  }
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const capitalizeFirstWord = (sentence) => {
  // if (typeof value !== "string") return "";

  if (!sentence || sentence.length === 0) {
    return "";
  }
  return sentence.charAt(0).toUpperCase() + sentence.slice(1)?.toLowerCase();
};

export const capitalizeSentences = (text) => {
  // Split the text into an array of sentences using a regular expression
  const sentences = text.split(/(?<=[.!?])\s+/);

  // Capitalize the first letter of each sentence
  const capitalizedSentences = sentences.map((sentence) => {
    // Trim the sentence to remove leading spaces
    sentence = sentence.trim();

    // Capitalize the first letter of the sentence
    if (sentence.length > 0) {
      sentence = sentence.charAt(0).toUpperCase() + sentence.slice(1);
    }

    return sentence;
  });

  // Join the sentences back together
  return capitalizedSentences.join(" ");
};

export const formatUnderScore = (value) => {
  let data = value?.toString();
  let newvalue = data?.replaceAll("_", " ");
  return value ? capitalize(newvalue) : "";
};

export const removeSpaces = (inputString) => {
  const wordsArray = inputString.split(" ");
  const stringWithoutSpaces = wordsArray.join("");
  return stringWithoutSpaces;
};

export const formatHyphen = (value) => {
  let data = value?.toString();
  let newvalue = data?.replaceAll("-", " ");
  return value ? capitalize(newvalue) : "";
};

export const replaceSpace = (value) => {
  let data = value?.toString();
  let newvalue = data?.replaceAll(" ", "_");
  return value ? capitalize(newvalue) : "";
};

export const mapStatusClass = (option) => {
  let res = "";
  let _option = option.toLowerCase();

  switch (_option) {
    case "new":
    case "application_incomplete":
      res = "new";
      break;
    case "selected":
      res = "selected";
      break;
    case "shortlisted":
      res = "shortlisted";
      break;
    case "admitted":
      res = "admitted";
      break;
    case "application completed":
      res = "employed";
      break;
    case "enrolled":
      res = "enrolled";
      break;
    case "in_training":
      res = "training";
      break;
    case "certified":
      res = "certified";
      break;
    case "employed":
      res = "employed";
      break;
    case "incomplete_alc_training":
      res = "incomplete_alc_training";
    break;
    case "completed_alc_training":
      res = "completed_alc_training";

    break;
    case "inactive":
      res = "inactive";
    break;
    case "placed":
    case "deployed":
      res = "employed";
      break;
    default:
      res = "";
      break;
  }

  return res;
};





export const stateOptions = [
  { value: "", label: "Select State" },

  { value: "Abia", label: "Abia" },
  { value: "Federal Capital Territory", label: "Abuja" },
  { value: "Adamawa", label: "Adamawa" },
  { value: "Akwa Ibom", label: "Akwa Ibom" },
  { value: "Anambra", label: "Anambra" },
  { value: "Bauchi", label: "Bauchi" },
  { value: "Bayelsa", label: "Bayelsa" },
  { value: "Benue", label: "Benue" },
  { value: "Borno", label: "Borno" },
  { value: "Cross River", label: "Cross River" },
  { value: "Delta", label: "Delta" },
  { value: "Ebonyi", label: "Ebonyi" },
  { value: "Edo", label: "Edo" },
  { value: "Ekiti", label: "Ekiti" },
  { value: "Enugu", label: "Enugu" },
  { value: "Gombe", label: "Gombe" },
  { value: "Imo", label: "Imo" },
  { value: "Jigawa", label: "Jigawa" },
  { value: "Kaduna", label: "Kaduna" },
  { value: "Kano", label: "Kano" },
  { value: "Katsina", label: "Katsina" },
  { value: "Kebbi", label: "Kebbi" },
  { value: "Kogi", label: "Kogi" },
  { value: "Kwara", label: "Kwara" },
  { value: "Lagos", label: "Lagos" },
  { value: "Nasarawa", label: "Nasarawa" },
  { value: "Niger", label: "Niger" },
  { value: "Ogun", label: "Ogun" },
  { value: "Ondo", label: "Ondo" },
  { value: "Osun", label: "Osun" },
  { value: "Oyo", label: "Oyo" },
  { value: "Plateau", label: "Plateau" },
  { value: "Rivers", label: "Rivers" },
  { value: "Sokoto", label: "Sokoto" },
  { value: "Taraba", label: "Taraba" },
  { value: "Yobe", label: "Yobe" },
  { value: "Zamfara", label: "Zamfara" },
];





export const API_TAGS = {
  GET_BUSINESS: "GET_BUSINESS",
  GET_DOCUMENTS: "GET_DOCUMENTS",
  GET_ALL_BUSINESS:"GET_ALL_BUSINESS",
  CREATE_REQUEST:"CREATE_REQUEST",

  GET_ALL_LINCENSE: "GET_ALL_LINCENSE",
  UPDATE_LINCENSE: "UPDATE_LINCENSE",
  DELETE_LINCENSE: "DELETE_LINCENSE",
  CREATE_LINCENSE: "CREATE_LINCENSE",

  GET_ALL_INVESTMENT_ROUND:" GET_ALL_INVESTMENT_ROUND",
  GET_ALL_INVESTMENTS:"GET_ALL_INVESTMENTS",
  GET_ALL_TEAMS:"GET_ALL_TEAMS",

  GET_ALL_STAKEHOLDERS:"GET_ALL_STAKEHOLDERS",
  GET_ALL_PLANS:"GET_ALL_PLANS",

  PERFORM_CREDIT_CHECK:"PERFORM_CREDIT_CHECK",
  GET_CREDIT_CHECKS:"GET_CREDIT_CHECKS",
  RERUN_CREDIT_CHECK:"RERUN_CREDIT_CHECK",
  GET_ALL_BANK_ACCOUNTS:"GET_ALL_BANK_ACCOUNTS",
  ADD_BANK_ACCOUNT:"ADD_BANK_ACCOUNT",
  UPDATE_BANK_ACCOUNT:"UPDATE_BANK_ACCOUNT",
  DELETE_BANK_ACCOUNT:"DELETE_BANK_ACCOUNT",

  GET_ALL_TRANSACTIONS:"GET_ALL_TRANSACTIONS",

  GET_BLACKLIST:"GET_BLACKLIST",
  ADD_TO_BLACKLIST:"ADD_TO_BLACKLIST",
  DELETE_FROM_BLACKLIST:"DELETE_FROM_BLACKLIST",
  UPDATE_BLACKLIST:"UPDATE_BLACKLIST",

  CREATE_LOAN_BENEFICIARY:"CREATE_LOAN_BENEFICIARY",
  GET_ALL_BENEFICIARY:"GET_ALL_BENEFICIARY",
  GET_SINGLE_BENEFICIARY:"GET_SINGLE_BENEFICIARY",
  DELETE_SINGLE_BENEFICIARY:"DELETE_SINGLE_BENEFICIARY",
  INITIATE_MANDATE:"INITIATE_MANDATE",
  FINALIZE_MANDATE:"FINALIZE_MANDATE",

  GET_ALL_TEAM_MEMBERS:"GET_ALL_TEAM_MEMBERS:",
  ADD_TEAM_MEMBER:"ADD_TEAM_MEMBER",
  DEACTIVATE_TEAM_MEMBER:"DEACTIVATE_TEAM_MEMBER"
};

export const mapLevel = (option) => {
  let res = "";
  let _option = option?.toLowerCase();

  switch (_option) {
    case "Beginner Level (zero 0 Or Less Than 1year Experience)".toLowerCase():
    case "Beginner level (Zero 0 or less than 1 year experience)".toLowerCase():
      res = "Beginner";
      break;
    case "Intermediate Level (1 To 4 Years Experience)".toLowerCase():
    case "Intermediate".toLowerCase():
      res = "Intermediate";
      break;
    case "Advanced Level (4+ Years Of Programming Experience)".toLowerCase():
    case "Advanced level (4+ years of experience)".toLowerCase():
      res = "Advanced";
      break;
    default:
      res = "";
      break;
  }

  return res;
};

export const mapBadge = (option) => {
  let res = "";
  let _option = option?.toString().toLowerCase() ?? "";

  switch (_option) {
    case "success":
    case "active":
    case "approved":
    case "disbursed":
    case "paid":
    case "true":
      res = "green";
      break;
    case "pending":
    case "awaiting":
      res = "orange";
      break;
    case "inactive":
    case "failed":
    case "canceled":
    case "rejected":
      res = "red";
      break;
    default:
      res = "green";
      break;
  }

  return res;
};

export const mapBadgeColor = (option) => {
  let res = "";
  let _option = option?.toString().toLowerCase() ?? "";

  switch (_option) {
    case "success":
    case "active":
    case "approved":
    case "disbursed":
    case "paid":
    case "true":
      res = "success";
      break;
    case "pending":
    case "awaiting":
      res = "pending";
      break;
    case "inactive":
    case "failed":
    case "canceled":
    case "rejected":
      res = "danger";
      break;
    default:
      res = "success";
      break;
  }

  return res;
};

export const countWords = (sentence) => {
  const words = sentence.split(/\s+/);
  const filteredWords = words.filter((word) => word.trim() !== "");
  return filteredWords.length;
};

export const wordCountValidator = (maxWords) => {
  return Yup.string().test(
    "wordCount",
    `Maximum ${maxWords} words allowed.`,
    (value) => {
      if (!value) return true;

      const wordArray = value.trim().split(/\s+/);
      return wordArray.length <= maxWords;
    }
  );
};

export const truncateString = (str, maxLength = 30) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  } else {
    return str;
  }
};


export const thousandSeparator = (x) => {
  let amount = Number(x);
  return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const amountConverter = (x) => {
  let amount = Number(x).toFixed(2);
  let currency = amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${currency}`;
};

export const generateRandomString = (length= 5)  =>{
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}



export const bankList = [
  { id: "1", bank: "Access Bank" ,bank_code:"044" },
  { id: "2", bank: "Citibank",bank_code:"023" },
  { id: "3", bank: "Diamond Bank",bank_code:"063" },
  { id: "4", bank: "Dynamic Standard Bank",bank_code:"423" },
  { id: "5", bank: "Ecobank Nigeria",bank_code:"050" },
  { id: "6", bank: "Fidelity Bank Nigeria",bank_code:"070" },
  { id: "7", bank: "First Bank of Nigeria",bank_code:"011" },
  { id: "8", bank: "First City Monument Bank",bank_code:"214" },
  { id: "9", bank: "Guaranty Trust Bank",bank_code:"058" },
  { id: "10", bank: "Heritage Bank Plc",bank_code:"030" },
  { id: "11", bank: "Jaiz Bank",bank_code:"301" },
  { id: "12", bank: "Keystone Bank Limited",bank_code:"082" },
  { id: "13", bank: "Providus Bank Plc",bank_code:"101" },
  { id: "14", bank: "Polaris Bank",bank_code:"076" },
  { id: "15", bank: "Stanbic IBTC Bank Nigeria Limited",bank_code:"221" },
  { id: "16", bank: "Standard Chartered Bank",bank_code:"068" },
  { id: "17", bank: "Sterling Bank",bank_code:"232" },
  { id: "18", bank: "Suntrust Bank Nigeria Limited",bank_code:"100" },
  { id: "19", bank: "Union Bank of Nigeria",bank_code:"032" },
  { id: "20", bank: "United Bank for Africa",bank_code:"033" },
  { id: "21", bank: "Unity Bank Plc",bank_code:"215" },
  { id: "22", bank: "Wema Bank",bank_code:"035" },
  { id: "23", bank: "Zenith Bank",bank_code:"057" }
];

export const checkTypes = [
  {  value:"individual",label:'Individual' },
  {  value:"corporate",label:'Corporate' },
]

export const categoryTypes = [
  {  value:"debtor",label:'Debtor' },
  {  value:"terrorism",label:'Terrorism' },
  {  value:"fraud",label:'Fraud' },
]

export const getCountryByCode = (code) => {
  const country = countries.find(c => c.code === code);
  return country ? country?.country : "No Country";
}

export const countries =  [
  { "id": "15555", "country": "Nigeria", "code": "NG" },
  // { "id": "1", "country": "Afghanistan", "code": "AF" },
  // { "id": "2", "country": "Albania", "code": "AL" },
  // { "id": "3", "country": "Algeria", "code": "DZ" },
  // { "id": "4", "country": "Andorra", "code": "AD" },
  // { "id": "5", "country": "Angola", "code": "AO" },
  // { "id": "6", "country": "Antigua and Barbuda", "code": "AG" },
  // { "id": "7", "country": "Argentina", "code": "AR" },
  // { "id": "8", "country": "Armenia", "code": "AM" }
]


export const Plans =  [
  {value:"Daily",label:"Daily"},
  {value:"Monthly",label:"Monthly"},
  {value:"Yearly",label:"Yearly"}
]

export const Gender =  [
  {value:"Male",label:"Male"},
  {value:"Female",label:"Female"},
]

export const getBankByCode = (bank_code) => {
  const bank = bankList.find(b => b.bank_code === bank_code);
  return bank ? bank.bank : null;
}


export const formatString = (inputString) => {
  const result = inputString.replace(/([a-z])([A-Z])/g, '$1 $2');
  return result.replace(/\b\w/g, char => char.toUpperCase());
}

export const LoanTerms = Array.from({ length: 96 }, (_, i) => ({
  value: `${i + 1} month${i + 1 > 1 ? 's' : ''}`,
  label: `${i + 1} Month${i + 1 > 1 ? 's' : ''}`,
}));

export const Sectors =  [
  {value:"Agency Banking",label:"Agency Banking"},
  {value:"Car Rental",label:"Car Rental"},
  {value:"Construction",label:"Construction"},
  {value:"Financial institutions",label:"Financial institutions"},
  {value:"Government and public Administration",label:"Government and public Administration"},
  {value:"Insurance",label:"Insurance"},
  {value:"Proptech",label:"Proptech"},
  {value:"Venture Capital",label:"Venture Capital"},
]


export const categories = [
  // { value: "Payment", label: "Payment" },
  // { value: "Credit", label: "Credit" },
  { value: "Commercial Banking License", label: "Commercial Banking License" },
  { value: "Microfinance Bank License", label: "Microfinance Bank License" },
  { value: "Merchant Banking License", label: "Merchant Banking License" },
  { value: "Development Finance Institution License", label: "Development Finance Institution License" },
  { value: "Non-Interest (Islamic) Banking License", label: "Non-Interest (Islamic) Banking License" },
  { value: "Payment Service Bank License", label: "Payment Service Bank License" },
  { value: "Primary Mortgage Institution License", label: "Primary Mortgage Institution License" },
  { value: "Finance Company License", label: "Finance Company License" },
  { value: "Bureau De Change License", label: "Bureau De Change License" },
  { value: "Mobile Money Operator License", label: "Mobile Money Operator License" },
  { value: "Super-Agent License", label: "Super-Agent License" },
  { value: "Payment Terminal Service Provider (PTSP) License", label: "Payment Terminal Service Provider (PTSP) License" },
  { value: "Switching and Processing License", label: "Switching and Processing License" },
  { value: "International Money Transfer Operator (IMTO) License", label: "International Money Transfer Operator (IMTO) License" },
  { value: "Payment Solution Service Provider (PSSP) License", label: "Payment Solution Service Provider (PSSP) License" },
  { value: "Card Scheme License", label: "Card Scheme License" },
  { value: "Payment Gateway License", label: "Payment Gateway License" },
  { value: "Other Financial Institutions License", label: "Other Financial Institutions License" }
];


export const permissions = [
  {
    label:"Customer Support",
    value:"customer_support"
  },
  {
    label:"Admin",
    value:"admin"
  }
]

export const loan_status = [
  {
    label:"Pending",
    value:"pending"
  },
  {
    label:"Awaiting",
    value:"awaiting"
  },
  {
    label:"Approved",
    value:"approved"
  },
  {
    label:"Disbursed",
    value:"disbursed"
  },
  {
    label:"Paid",
    value:"paid"
  },
  {
    label:"Canceled",
    value:"canceled"
  },
  {
    label:"Rejected",
    value:"rejected"
  },
]


export const riskOnly = [
  {
    title:"Credit Risk Analysis Report",
    mark:true
  },
  {
    title:"Credit Score Overview",
    mark:true
  },
  {
    title:"Risk Assessment Summary",
    mark:true
  },
  {
    title:"Creditworthiness Evaluation",
    mark:true
  },
  {
    title:"List of Risk Factors",
    mark:false
  },
  {
    title:"Default Probability Indicator",
    mark:false
  },
  {
    title:"Personal Data Overview",
    mark:false
  },
  {
    title:"Credit History Overview",
    mark:false
  },
  {
    title:"AI-Generated Insights",
    mark:false
  },
  {
    title:"Recommendations for Risk Mitigation",
    mark:false
  }
]

export const riskLoan = [
  {
    title:"All Items in the Risk Package",
    mark:true
  },
  {
    title:"Loan Eligibility Assessment",
    mark:true
  },
  {
    title:"Loan Amount Prediction",
    mark:true
  },
  {
    title:"Loan Repayment Analysis",
    mark:true
  },
  {
    title:"Debt-to-Income Ratio Evaluation",
    mark:true
  },
  {
    title:"Customized Loan Offers",
    mark:true
  },
  {
    title:"Loan Risk Comparison",
    mark:true
  },
  {
    title:"Interest Rate Forecast",
    mark:true
  },
  {
    title:"Personalized Loan Recommendations",
    mark:true
  },
  {
    title:"Loan Application Support",
    mark:true
  }
]











