/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "utils/strings";
import baseQueryWithReauth from "./baseQuery";

export const teamApi = createApi({
  reducerPath: "teamApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [],
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => `merchant/team-management`,
      providesTags: [API_TAGS.GET_ALL_TEAM_MEMBERS],
    }),
    getSingleUser: builder.query({
      query: (id) => `merchant/team-management/${id}`,
    }),
    addMember: builder.mutation({
      query: (body) => ({
        url: `merchant/team-management/`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.ADD_TEAM_MEMBER],
      invalidatesTags: [API_TAGS.GET_ALL_TEAM_MEMBERS],
    }),
    deactivateUser: builder.mutation({
      query: (body) => ({
        url: `merchant/team-management/${body?.id}/`,
        method: "PUT",
        body,
      }),
      providesTags: [API_TAGS.DEACTIVATE_TEAM_MEMBER],
      invalidatesTags: [API_TAGS.GET_ALL_TEAM_MEMBERS],
    }),
   
  }),
});

export const {
  useGetAllUsersQuery,
  useGetSingleUserQuery,
  useDeactivateUserMutation,
  useAddMemberMutation,
} = teamApi;
