/** @format */

export const Path = {
 HOME: "/",
 SDK_PATH: "/sdk/verify/:sessionId",
 LOGIN:"/auth/login",
 REGISTER:"/auth/register",
 CONTACT:"/contact",
 FAQS:"/faqs",
 TERMS:"/terms-and-conditions",
 PRIVACY_POLICY:"/privacy-policy",
 DASHBOARD_OVERVIEW:"/dashboard/overview",
 DASHBOARD_REMINDER:"/dashboard/reminder",
 DASHBOARD_BUNDLES:"/dashboard/bundles",
 DASHBOARD_SETTINGS:"/dashboard/settings",
 DASHBOARD_SUPPORT:"/dashboard/support",
 DASHBOARD_CONTACT:"/dashboard/support/contact",
};

export const Colors = {
  white: "white",
  primary: "#33B469",
  brand_blue: "#26205E",
  blue200: "#2F80ED",
  dark100: "#010E0E",
  dark200: "#424242",
  dark300: "#53565A",
  grey100: "#EDEDED",
  grey200: "#E1E1E1",
  grey300: "#FBFBFD",
  grey500: "#F5F5F5",
  grey650:"#EAECF0",
  purple100: "#5045E6",

  blue100: "#0063F7",
  dark400: "#101828",
  grey400: "#E0E0E0",
  grey600: "#505D6F",
  grey800: "#667085",
};
