/* eslint-disable no-unused-vars */
import { useFormik } from "formik";
import AuthLayout from "layouts/auth";
import { Path } from "utils/constant.data";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { useLoginUserMutation } from "store/services/authAPI";
import { login } from "store/reducers/AuthReducer";
import { useToast, Spinner, Stack, Center } from "@chakra-ui/react";
import * as Yup from "yup";
import { Colors } from "utils/constant.data";
import { ReactComponent as GoogleLogo } from "icons/google.svg";

const LoginPage = () => {
  const dispatch = useDispatch();
  const [isShown, setIsSHown] = useState(false);
  const [loginUser, { isLoading: authenticating }] = useLoginUserMutation();
  const toast = useToast();
  const state = window.history?.state ?? null;

  const handleSubmit = async (values) => {
    await loginUser({
      email: values?.email,
      password: values?.password,
    })
      .unwrap()
      .then((data) => {
        dispatch(
          login({
            ...data?.data,
            formerPath: state?.from ? state?.from : null,
          })
        );
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.detail,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  return (
    <AuthLayout>
      <section className="flex justify-center pt-56">
        <section className="w-940 rounded-20 bg-white flex justify-center  flex-col h-full py-44 px-38 border-neutral100 border">
          <h1 className="text-h2 font-bold  text-dark100 mb-14">
            Sign In
          </h1>
         
          <form
            className="auth__form w-full mt-24"
            onSubmit={formik.handleSubmit}
          >
            <Stack>
              <button className="btn btn-outline !justify-start">
                <GoogleLogo />
                <span className="ml-12"> Sign in with Google</span>
              </button>
            </Stack>

            <Center className="my-16">
              <p className="text-p text-black">Or</p>
            </Center>

            <div className="auth-form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                aria-describedby="Email"
                placeholder="Enter your Email"
                {...formik.getFieldProps("email")}
              ></input>
              {formik.touched.email && formik.errors.email ? (
                <div className="error">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="auth-form-group">
              <label htmlFor="password">Password</label>
              <div className="password__container">
                <input
                  type={isShown ? "text" : "password"}
                  id="exampleInputEmail1"
                  aria-describedby="Password"
                  placeholder="Enter your password"
                  {...formik.getFieldProps("password")}
                />
                {/* <button type="button" onClick={togglePassword}>
                  <div className="icon">
                    <EyeIcon />
                    {isShown ? <EyeIcon /> : <i className="ti ti-eye fs-4"></i>}
                  </div>
                </button> */}
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </div>

            <div className="flex items-center justify-end">
              <p className="font-normal font-quicksand text-error text-h6 ">
                <Link to={Path.FORGOT_PASSWORD} className="text-blue" href="ff">
                  Forgot password?
                </Link>
              </p>
            </div>

            <div className="flex my-16">
              <button type="submit" className="btn btn-primary w-full">
                {authenticating ? <Spinner color={Colors.white} /> : "Login"}
              </button>
            </div>

            <p className="text-center  font-display text-[#696F79] font-normal  text-h6 mt-16">
              Don’t you have account?
              <Link
                to={Path.REGISTER}
                className="text-primary text-h6 pl-6 font-medium"
              >
                Sign Up
              </Link>
            </p>
          </form>
        </section>
      </section>
    </AuthLayout>
  );
};

export default LoginPage;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(4, "Password is too short - should be 4 chars minimum"),
});
