/** @format */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: {},
  profileInfo: {},
  activeBusiness:null
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setActiveBusiness: (state, { payload }) => {
      state.activeBusiness = payload;
    },
  },
});

export const { setLoading,setActiveBusiness } = profileSlice.actions;
export const profileSelector = (state) => state.profile;
const profileReducer = profileSlice.reducer;

export default profileReducer;


