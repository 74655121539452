/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useFormik } from "formik";
import { useToast, Spinner, Stack, Textarea } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import PublicLayout from "layouts/public";
import { useLoginUserMutation } from "store/services/authAPI";
import { login } from "store/reducers/AuthReducer";
import * as Yup from "yup";
import { Colors } from "utils/constant.data";

const ContactPage = () => {
  const dispatch = useDispatch();
  const [isShown, setIsSHown] = useState(false);
  const [loginUser, { isLoading: authenticating }] = useLoginUserMutation();
  const toast = useToast();
  const state = window.history?.state ?? null;

  const handleSubmit = async (values) => {
    await loginUser({
      email: values?.email,
      password: values?.password,
    })
      .unwrap()
      .then((data) => {
        dispatch(
          login({
            ...data?.data,
            formerPath: state?.from ? state?.from : null,
          })
        );
      })
      .catch((error) => {
        toast({
          title: "Error Occured",
          description: error?.data?.detail,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <PublicLayout>
      <Stack gap="0" className="bg-[#FAF8F1] !flex-row justify-center">
        <Stack className="w-[85%] md:w-3/4">
          <Stack className="justify-center items-center p-55 rounded-20 bg-white mt-56 ">
            <h1 className=" text-h2 md:text-[70px] md:leading-[73px] font-quicksand font-bold  text-black text-center">
              Get In Touch With Us
            </h1>

            <h2 className="text-dark200 font-medium text-base text-center mb-30">
              Your voice shapes our platform. Share your thoughts to help us
              enhance the experience and create the safest, most authentic
              dating community. We’re listening.
            </h2>
          </Stack>

          <Stack>
            <form
              className="auth__form w-full mt-24"
              onSubmit={formik.handleSubmit}
            >
              <div className="auth-form-group">
                <label htmlFor="email">Full Name</label>
                <input
                  type="email"
                  id="email"
                  aria-describedby="Email"
                  placeholder="Enter your Email"
                  {...formik.getFieldProps("email")}
                ></input>
                {formik.touched.email && formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : null}
              </div>
              <div className="auth-form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  aria-describedby="Email"
                  placeholder="Enter your Email"
                  {...formik.getFieldProps("email")}
                ></input>
                {formik.touched.email && formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : null}
              </div>

              <div className="form-row-full auth-form-group !mb-16">
                <label htmlFor="Loan Purpose">Loan Purpose</label>

                <Textarea
                  resize="none"
                  placeholder="Loan Purpose"
                  variant="filled"
                  className="!bg-white !border-neutral300 !rounded-12 !border-1 !text-black !h-60"
                  {...formik.getFieldProps("loan_purpose")}
                />

                {formik.touched.loan_purpose && formik.errors.loan_purpose ? (
                  <div className="error">{formik.errors.loan_purpose}</div>
                ) : null}
              </div>

              <div className="flex my-16 justify-center">
                <button type="submit" className="btn btn-primary w-">
                  {authenticating ? <Spinner color={Colors.white} /> : "Submit"}
                </button>
              </div>
            </form>
          </Stack>
        </Stack>
      </Stack>
    </PublicLayout>
  );
};

export default ContactPage;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(4, "Password is too short - should be 4 chars minimum"),
});
