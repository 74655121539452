/* eslint-disable no-unused-vars */
/** @format */

import { Navigate, Outlet } from "react-router-dom";
import { Path } from "utils/constant.data";


export const AuthUserRoute = ({ user }) => {
  
  return user && user?.access ? (
    <Outlet />
  ) : (
    // <Navigate to={Path.LOGIN} replace />
    <Outlet />
  );
};



export const GuestRoute = ({ user }) => {

  return user && user?.access ?  (
    <Navigate
      to={
        Path.DASHBOARD_OVERVIEW
      }
      replace
    />
  ) : (
    <Outlet />
  );
};
