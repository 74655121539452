/* eslint-disable no-unused-vars */
import { useState } from "react";
import { ReactComponent as WeaveLogo } from "icons/weave.svg";
import { ReactComponent as SearchIcon } from "icons/search.svg";
import { ReactComponent as NotificationIcon } from "icons/notification.svg";

const DashboardHeader = ({ path }) => {
  const [searchTerm, setSearchTerm] = useState();

  return (
    <header className="weave--header">
      <figure >
        <WeaveLogo />
      </figure>

      <div className="border-1 border-dark-100 basis-1/2 bg-white relative px-25 py-14 flex items-center rounded-12">
        <button
          onClick={() => {}}
          className="flex items-center justify-center w-24 cursor-pointer mr-9"
        >
          <SearchIcon />
        </button>
        <input
          placeholder="Search Keyword"
          onChange={(e) => setSearchTerm(e.target.value)}
          className="bg-transparent border-none text-dark100 mt-2  font-graphik flex-grow focus:outline-none focus:border-0 placeholder-black placeholder-opacity-70"
        ></input>
      </div>

      <div className="flex otems-center">
        <button className="btn btn-primary !px-50">Create Card</button>
        <button className="ml-24">
          <NotificationIcon />
        </button>
      </div>
    </header>
  );
};

export default DashboardHeader;
