/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "utils/strings";
import baseQueryWithReauth from "./baseQuery";

export const loanApi = createApi({
  reducerPath: "loanApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
   
  ],
  endpoints: (builder) => ({

    createLoan: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/loan-beneficiary/${body?.id}/create_loan/`,
          method: "POST",
          body,
        };
      },
    }),
    createBenefeciary: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/loan-beneficiary/`,
          method: "POST",
          body,
        };
      },
      providesTags: [API_TAGS.CREATE_LOAN_BENEFICIARY],
    }),
    getAllBeneficiary: builder.query({
      query: () => `merchant/loan-beneficiary`,
      providesTags: [API_TAGS.GET_ALL_BENEFICIARY],

    }),
    getSingleBeneficiary: builder.query({
      query: (id) => `merchant/loan-beneficiary/${id}`,
      providesTags: [API_TAGS.GET_SINGLE_BENEFICIARY],

    }),
    deleteBeneficiary: builder.mutation({
      query: (body) => ({
        url: `merchant/loan-beneficiary/${body?.id}/delete`,
        method: "DELETE",
      }),
      providesTags: [API_TAGS.DELETE_SINGLE_BENEFICIARY],
    }),
    deleteLoanBeneficiary: builder.mutation({
      query: (body) => ({
        url: `merchant/loan-beneficiary/${body?.id}/`,
        method: "DELETE",
         invalidatesTags: [API_TAGS.GET_ALL_BENEFICIARY],
      }),
    }),
    initiateMandate: builder.mutation({
      query: (body) => ({
        url: `merchant/loan-beneficiary/${body?.id}/generate_consent/`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.INITIATE_MANDATE],
    }),
    finalizeMandate: builder.mutation({
      query: (body) => ({
        url: `merchant/loan-beneficiary/${body?.id}/generate_account/`,
        method: "POST",
        body,
      }),
      providesTags: [API_TAGS.FINALIZE_MANDATE],
    }),

    getWalletHistory: builder.query({
      query: () => ({
        url: `merchant/wallet/history`,
      }),
      providesTags: [API_TAGS.FINALIZE_MANDATE],
    }),
    fundWallet: builder.mutation({
      query: (body) => {
        return {
          url: `merchant/wallet/fund`,
          method: "POST",
          body,
        };
      },
    }),

  }),
});

export const {
  useCreateLoanMutation,
 useCreateBenefeciaryMutation,
 useGetAllBeneficiaryQuery,
 useGetSingleBeneficiaryQuery,
 useDeleteBeneficiaryMutation,
 useInitiateMandateMutation,
 useFinalizeMandateMutation,
useGetWalletHistoryQuery,
 useFundWalletMutation,
 useDeleteLoanBeneficiaryMutation

} = loanApi;
