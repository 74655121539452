/* eslint-disable no-unused-vars */
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as HomeIcon } from "icons/home.svg";
import { ReactComponent as ReminderIcon } from "icons/reminder.svg";
import { ReactComponent as BundelIcon } from "icons/bundle.svg";
import { ReactComponent as AccountIcon } from "icons/account.svg";
import { ReactComponent as SupportIcon } from "icons/support.svg";
import { Path } from "utils/constant.data";

const DashboardSidebar = () => {
  const navContainer = useRef();
  const nav = useRef();

  const showNav = () => {
    navContainer.current.classList.remove("flex");
    nav.current.classList.remove("flex");
  };

  const path = window.location.pathname.split("/");

  return (
    <section ref={navContainer} className="sidebar--container">
      {/* <button
        className="flex items-center justify-center bg-white w-6 h-6 xlg:hidden absolute z-40 top-0 right-0"
        onClick={showNav}
      >
        <CloseIcon />
      </button> */}

      <section ref={nav} className="py-32 pl-42 w-full">
        <h4 className="text-md font-medium mb-18">Your Dashboard</h4>
        <ul className="flex flex-col">
          <li>
            <Link
              to={Path.DASHBOARD_OVERVIEW}
              className={`sidebar__link group ${
                path.includes("overview") ? "active" : ""
              }`}
            >
              <HomeIcon className="group-hover:fill-blue act" />
              <span>Dashboard </span>
            </Link>
          </li>
          <li>
            <Link
              to={Path.DASHBOARD_REMINDER}
              className={`sidebar__link  group ${
                path.includes("reminder") ? "active" : ""
              }`}
            >
              <ReminderIcon className="group-hover:fill-blue act" />
              <span>Reminders </span>
            </Link>
          </li>
          <li>
            <Link
              to={Path.DASHBOARD_BUNDLES}
              className={`sidebar__link  group ${
                path.includes("bundles") ? "active" : ""
              }`}
            >
              <BundelIcon className="group-hover:fill-blue act" />
              <span>Bundle </span>
            </Link>
          </li>

          <li>
            <Link
              to={Path.DASHBOARD_SETTINGS}
              className={`sidebar__link  group ${
                path.includes("settings") ? "active" : ""
              }`}
            >
              <AccountIcon className="group-hover:fill-blue act" />
              <span>Account </span>
            </Link>
          </li>
          <li>
            <Link
              to={Path.DASHBOARD_SUPPORT}
              className={`sidebar__link  group ${
                path.includes("support") ? "active" : ""
              }`}
            >
              <SupportIcon className="group-hover:fill-blue act" />
              <span>Support </span>
            </Link>
          </li>
        </ul>
      </section>
    </section>
  );
};

export default DashboardSidebar;
