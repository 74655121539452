/* eslint-disable no-unused-vars */
import React from "react";
import { Stack, Center, Box } from "@chakra-ui/react";
import PublicLayout from "layouts/public";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

const FaqPage = () => {
  return (
    <PublicLayout>
      <Stack gap="0" className="bg-[#FAF8F1] !flex-row justify-center ">
        <Stack className="w-[85%] md:w-3/4 mt-56">
          <Center>
            <h2 className="title w-500 mb-50 text-center">
              {" "}
              Frequently Ask Questions
            </h2>
          </Center>

          <Stack>
            <Accordion>
              <AccordionItem className="!bg-white !border-0 !rounded-12 !mb-16 !p-32 !text-h5">
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left"  className="!text-lg !font-montserrat !font-normal !font-bold">
                      What makes charend different from other dating apps?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} className="!text-lg !font-montserrat !font-normal">
                  Charend prioritizes safety and authenticity above all. We
                  perform comprehensive criminal background checks and identity
                  verification through our parent company, Prembly Inc. We also
                  focus on creating meaningful connections without the
                  distractions of rankings or gimmicky features.
                </AccordionPanel>
              </AccordionItem>

             
            </Accordion>
          </Stack>
        </Stack>
      </Stack>
    </PublicLayout>
  );
};

export default FaqPage;
