/** @format */

import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import {thunk} from "redux-thunk";
import profileReducer from "./reducers/ProfileReducer";


import {
  authApi,
  businessApi,
  loanApi,
  teamApi
} from "./services";

const middleware = applyMiddleware(
  thunk,
  authApi.middleware,
  businessApi.middleware,
  loanApi.middleware,
  teamApi.middleware
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  profile: profileReducer,
  [authApi.reducerPath]: authApi.reducer,
  [businessApi.reducerPath]:  businessApi.reducer,
  [loanApi.reducerPath]:  loanApi.reducer,
  [teamApi.reducerPath]:  teamApi.reducer,
});

export const store = createStore(reducers, composeEnhancers(middleware));
