import DashboardLayout from "layouts/dashboard";
import { HStack, Stack, Text } from "@chakra-ui/react";
import {
  PopoverContent,
  Popover,
  PopoverTrigger,
  PopoverBody,
} from "@chakra-ui/react";
import { ReactComponent as CaretIcon } from "icons/caretdown.svg";

const DashboardOverview = () => {
  return (
    <DashboardLayout>
      <section className="mx-flush">
        <Stack>
          <Stack gap="0">
            <HStack alignItems="center">
              <Text className="text-h4 font-bold text-black ">Given </Text>
              <Popover placement="bottom">
                <PopoverTrigger>
                  <button className="cursor-pointer text-secondary font-semibold mt-5">
                    <CaretIcon className="fill-primaryy" />
                  </button>
                </PopoverTrigger>
                <PopoverContent p="0" w="220px">
                  <PopoverBody>
                    <div className="flex flex-col  font-inter text-dark100 p-0">
                      <Stack p="13px">
                        <ul className="cursor-pointer table_more">
                          <li className="group">
                            <span className="group-hover:text-secondary group-hover:font-semibold">
                              Given
                            </span>
                          </li>
                          <li className="group">
                            <span className="group-hover:text-secondary group-hover:font-semibold">
                              Recieved
                            </span>
                          </li>
                        </ul>
                      </Stack>
                    </div>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
            <Text className="text-neutral700">
              Cards you created or you contributed to
            </Text>
          </Stack>
        </Stack>
      </section>
    </DashboardLayout>
  );
};

export default DashboardOverview;
