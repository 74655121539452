import "react-multi-carousel/lib/styles.css";
import Navbar from "./components/Navbar";

const AuthLayout = ({ children }) => {
 

  return (
    <section className="flex flex-col min-h-screen">
      
      <Navbar />
      <section className="basis-full flex-shrink-0 overflow-hidden">
        {children}
      </section>
    </section>
  );
};

export default AuthLayout;
