/** @format */
import { encrypt } from "utils/secure";

import { createSlice } from "@reduxjs/toolkit";
import { Path } from "utils/constant.data";

const initialState = {
  isAuth: false,
  currentUser: null,
  isLoading: false,
  fromLogin: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuth: (state, { payload }) => {
      state.isAuth = payload;
    },
    setCurrentUser: (state, { payload }) => {
      state.currentUser = { ...payload };
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setFromLogin: (state, { payload }) => {
      state.fromLogin = payload;
    },
  },
});

export const { setIsAuth, setCurrentUser, setLoading, setFromLogin } =
  authSlice.actions;
export const authSelector = (state) => state.auth;
export default authSlice.reducer;

export const setUser = (payload) => async (dispatch) => {
  dispatch(setCurrentUser(payload));
};

export const setActionSource = (payload) => async (dispatch) => {
  dispatch(setFromLogin(payload));
};

export const login = (payload) => async (dispatch) => {

  if (payload?.token) {
    await dispatch(setCurrentUser(payload));
    window.localStorage.setItem("UTMAT", encrypt(payload?.token?.access));
    window.localStorage.setItem(
      "UTMUSER",
      encrypt(JSON.stringify({
        ...payload?.user,
        access: payload?.token?.access,
        refresh: payload?.token?.refresh
      }))
    );


    window.open(
      payload?.token
        ? payload?.source === "register" ? Path.DASHBOARD_ONBOARDING : payload?.formerPath ? payload?.formerPath : Path.DASHBOARD_OVERVIEW
        : Path.LOGIN,
      "_self"
    );
  }
};

export const logOut = () => async (dispatch) => {
  window.localStorage.removeItem("UTAT");
  window.localStorage.removeItem("UTMUSER");
  window.open(Path.LOGIN, "_self");
};
