/* eslint-disable no-unused-vars */
import React from "react";
import { Stack, HStack } from "@chakra-ui/react";
import PublicLayout from "layouts/public";
import { ReactComponent as Safety } from "icons/safety.svg";
import { ReactComponent as Gimmicks } from "icons/gimmicks.svg";
import { ReactComponent as Privacy } from "icons/privacy.svg";
import { ReactComponent as Inclusive } from "icons/inclusive.svg";
import A from "../../image/a.png"
import pattern from "../../image/pattern.png"

const LandingPage = () => {
  const tags = [
    "Safety Features",
    "Background Checked",
    "No Profile Ranking",
    "No Gimmicks",
    "Betted Connections",
  ];
  return (
    <PublicLayout>
      <Stack gap="0">
        <Stack className="justify-center items-center h-screen md:h-[90vh] !relative hero--section">
          <h1 className="text-h1 sm:text-[70px] font-quicksand font-bold  text-black text-center">
            Date with Confidence
          </h1>

          <h2 className="text-dark200 font-medium text-sm sm:text-2xl mb-30  text-center">
            Made by friends - for your peace of mind.
          </h2>
          <button className="btn btn-primary">Download Charend</button>

          <Stack className="absolute bottom-60 left-0 w-full flex-column md:!flex-row home--tags items-center justify-center">
            {tags.map((_) => (
              <button> {_}</button>
            ))}
          </Stack>

          <figure className="a hidden md:flex">
            <img src={A} alt="picc" />
          </figure>
          <figure className="b  hidden md:flex">
            <img src={A} alt="picc" />
          </figure>
          <figure className="c  hidden md:flex" >
            <img src={A} alt="picc" />
          </figure>
          <figure className="d  hidden md:flex">
            <img src={A} alt="picc" />
          </figure>
        </Stack>
        <HStack className="bg-primary min-h-715 lg:h-715 flex-row p-50 lg:px-200"  id="about">
          <div className="hidden lg:block basis-1/2">
            <figure className="h-480 w-690 overflow-hidden"> 
              <img src={pattern} alt="pattern"  className="w-full h-full object-cover"/>
            </figure>
          </div>
          <div className="basis-full lg:basis-1/2">
            <Stack className="bg-[#FAF8F1]  rounded-12 p-45 lg:-m-70">
              <h2 className="title w-175 mb-16"> About Us</h2>
              <p className="text-p font-medium font-montserrat text-black">
                We’re not just a tech company; we’re on a mission to redefine
                dating with safety, trust, and inclusivity at its core. What
                began as a passion project for friends has grown into a nation
                wide platform—because everyone deserves to feel secure while
                making meaningful connections. As leaders in identity
                verifications, we set the standard for trust and security. No
                distractions, no gimmicks—just authentic connections built on a
                foundation of safety.
              </p>
            </Stack>
          </div>
        </HStack>
        <Stack id="features" className="pt-50 pb-200 px-100 items-center bg-[#FAF8F1]">
          <h2 className="title w-180 mb-50"> Features</h2>

          <section className="grid grid-cols-1 lg:grid-cols-4 gap-16">
            <Stack className="items-center justify-center bg-white rounded-20 min-h-282 px-25 py-44">
              <Safety />
              <h5 className="font-montserrat text-p font-medium text-center text-back">
                Safety First
              </h5>
              <h6 className="font-montserrat text-12 font-normal text-center text-back">
                We ensure every user is thoroughly vetted, prioritizing your
                safety with every connection.
              </h6>
            </Stack>
            <Stack className="items-center justify-center bg-white rounded-20 min-h-282 px-25 py-44">
              <Gimmicks />

              <h5 className="font-montserrat text-p font-medium text-center text-back">
                No Gimmicks, Just Connection
              </h5>
              <h6 className="font-montserrat text-12 font-normal text-center text-back">
                We focus on authenticity, with no profile rankings or
                distractions—just genuine connections that matter.
              </h6>
            </Stack>
            <Stack className="items-center justify-center bg-white rounded-20 min-h-282 px-25 py-44">
              <Privacy />

              <h5 className="font-montserrat text-p font-medium text-center text-back">
                Privacy in Your Hands
              </h5>
              <h6 className="font-montserrat text-12 font-normal text-center text-back">
                You control your data. Your data will never be used outside the
                app’s core functions, so you can trust us to keep your privacy
                intact.
              </h6>
            </Stack>
            <Stack className="items-center justify-center bg-white rounded-20 min-h-282 px-25 py-44">
              <Inclusive />

              <h5 className="font-montserrat text-p font-medium text-center text-back">
                Inclusive Community
              </h5>
              <h6 className="font-montserrat text-12 font-normal text-center text-back">
                A space for all, designed to be safe and inclusive.
              </h6>
            </Stack>
          </section>
        </Stack>
      </Stack>
    </PublicLayout>
  );
};

export default LandingPage;
